import 'slick-carousel';
import { reloadicons } from './svg.js';
import { closeAllMenus } from './mobile.js';
import { dispatchViewCartEvent } from './events.js';
import { pageLoadingOn, pageLoadingOff, showRatingWhenInView , openNav, closeNav, hideOverlay, validateForm, copyTextToClipboard, ajaxForms, getUserDevice, setCookie, IsCEP} from './utilities.js';
import Modal from 'bootstrap/js/dist/modal';
import '../../themes/main.css'
import '../../themes/carrinho/carrinho.css'

jQuery(async function () {

	//await import('qtip2')
	await import('./svg.js');
	await import('./carrinho.js');
	await import('./mobile.js');
	await import('jquery-ui/ui/widgets/autocomplete');
	await import('./rating/star-rating.js');
	await import('./rating/theme.js');
	await import('./favoritos.js');
	const { default: Blazy } = await import('blazy');

	if ($("#loader").length > 0) {
		$("#loader").fadeOut("");
	}

	$('.continuar-compra-btn').on('click', function () {
		closeNav();
	});

	/*
	* Fecha o alert
	*/
	$('body').on('click', '.alert a.close', function () {
		if ($(this).parent().parent().hasClass('has-error')) {
			$(this).parent().removeAttr("style");
			$(this).parent().addClass("displaynone");
			$(this).parent().parent().removeClass('has-error');
			if ($(this).parent().hasClass('alert-add')) {
				$(this).parent().remove();
			}
		} else {
			$(this).parent().removeAttr("style");
			$(this).parent().addClass("displaynone");
		}
	});

	/*
		Menu todos departamentos
	*/
	$('#overlay-geral ').mouseout(function () {
		var anterior = $('.toda-loja').find('.active-menu-d');
		anterior.removeClass('active-menu-d');
		anterior.addClass('d-none');
	});

	$(".toda-loja .item-menu>a").on({
		'mouseover': function (e) {
			var anterior = $('.toda-loja-itens.active-menu-d');
			anterior.removeClass('active-menu-d');
			anterior.addClass('d-none');
			var url = $(this).attr('data-url');
			//console.log(url);
			var ul = $('.toda-loja-itens#' + url);
			ul.removeClass('d-none');
			ul.addClass('active-menu-d');
		},
	});

	/*
	* Autocomplete da busca
	*/
	var cache = {}, lastXhr;
	//var autoMax = $('#form-search input').outerWidth();
	$("input.search-topo").autocomplete({
		minLength: 2,
		position: {
			my: "left top",
			at: "left bottom"
		},
		source: function (request, response) {
			var term = request.term;
			if (term in cache) {
				response(cache[term]);
				return;
			}
			lastXhr = $.getJSON("/pesquisa/", request, function (data, status, xhr) {
				var results;
				if (xhr === lastXhr) {
					results = $.map(data, function (item) {
						return {
							value: item.value,
							img: item.img,
							label: item.label,
							id: item.id,
							price: item.price,
							total: item.total
						};
					});
					cache[term] = results;
					response(results);
				}
			});
		},
		create: function () {
			$(this).data("uiAutocomplete")._renderItem = function (ul, item) {
				if (item.label == 'inicio') {
					return $("<li/>").appendTo(ul);

				} else if (item.label == 'final') {
					return $("<li/>")
						.data("item.autocomplete", item)
						.append("<a class='final'>Ver todos os " + item.total + " produtos </a>")
						.appendTo(ul);
				} else if (item.label == 'categoria') {
					return $("<li/>")
						.data("item.autocomplete", item)
						.append("<a class='categoria'>" + item.value + "</a>")
						.appendTo(ul);
				} else if (item.label == 'categorias') {
					return $("<li/>")
						.data("item.autocomplete", item)
						// .append("<span class='categorias'>" + item.value + "</span>")
						.appendTo(ul);
				} else {
					if (item.value != null) {
						return $("<li/>")
							.data("item.autocomplete", item)
							.append("<a class='result'><div class='img'><img src='" + item.img + "' /></div><div class='info'><div class='name'>" + item.value + "</div><div class='price'>" + item.price + "</div></div></a>")
							.appendTo(ul);
					}
				}
			}
		},
		select: function (event, ui) {
			window.location.href = ui.item.id;
		}
	});

	var bLazy = new Blazy({
		offset: 20,
		selector: '.b-lazy',
	});

	$('form#form-zipcode-header .zipcode-calcular').on("click", function (e) {
		e.preventDefault;
		
		var zipcode = $("form#form-zipcode-header input[name=zipcode-header]").val();
		if (IsCEP(zipcode)) {
			if(zipcode.indexOf("-") != 5) zipcode = zipcode.slice(0, 5) + "-" + zipcode.slice(5);
			setCookie("zipcode", zipcode, 30);
			$("#cep-container strong").html(zipcode);
			$("#cep-mobile strong").html(zipcode);
			$('.dropdown-cep').removeClass('active');
			$('#overlay-geral').removeClass("active");
			//Preenche o cep na pagina do produto
			$('#productZipcode').val(zipcode);
			//Preenche o cep no carrinho de compras
			$('#purchaseListZipcode').attr('value',zipcode);
			$('.purchase-list-footer .carriers').html('');
		}else{
			$("form#form-zipcode-header").find('.main-alert').html('<a class="close" title="Fechar">×</a>');
			$("form#form-zipcode-header").find('.main-alert').append('<p><span/>Informe o Cep para cadastro</p>');
			$("form#form-zipcode-header").find('.main-alert').fadeIn();
			$("form#form-zipcode-header").find('.main-alert').removeClass("displaynone");
		}
	});
	$('form#form-zipcode-header-mobile .zipcode-calcular').on("click", function (e) {
		e.preventDefault;
		
		var zipcode = $("form#form-zipcode-header-mobile input[name=zipcode-header]").val();
		if (IsCEP(zipcode)) {
			if(zipcode.indexOf("-") != 5) zipcode = zipcode.slice(0, 5) + "-" + zipcode.slice(5);
			setCookie("zipcode", zipcode, 30);
			$("#cep-container strong").html(zipcode);
			$("#cep-mobile strong").html(zipcode);
			$('.dropdown-cep').removeClass('active');
			$('#overlay-geral').removeClass("active");
			//Preenche o cep na pagina do produto
			$('#productZipcode').val(zipcode);
			//Preenche o cep no carrinho de compras
			$('#purchaseListZipcode').attr('value',zipcode);
			$('.purchase-list-footer .carriers').html('');
		}else{
			$("form#form-zipcode-header-mobile").find('.main-alert').html('<a class="close" title="Fechar">×</a>');
			$("form#form-zipcode-header-mobile").find('.main-alert').append('<p><span/>Informe o Cep para cadastro</p>');
			$("form#form-zipcode-header-mobile").find('.main-alert').fadeIn();
			$("form#form-zipcode-header-mobile").find('.main-alert').removeClass("displaynone");
		}
	});


	$(".form-search-header").on("click", "i", function () {
		var form = $(this).parent();
		var term = form.find('input').val();

		if (term == "") {
			if (form.find('input').hasClass("active")) {
				form.find('input').removeClass("active");
			} else {
				form.find('input').addClass("active");
				form.find('input').focus();
			}
		} else {
			form.submit();
		}
	});

	$(document).on("scroll", function() {
		const isMobile = $(window).width() <= 991;

		if (isMobile) {
			if ($(this).scrollTop() >= 300) {
				$('#cep-mobile').addClass("hidden")
				$('#mobile-headerSearch').addClass("hidden")
			} else {
				$('#cep-mobile').removeClass("hidden")
				$('#mobile-headerSearch').removeClass("hidden")
			}
		}
	})

	$(document).on("blur", ".form-search-header:visible input", function () {
		if ($(this).hasClass("active")) {
			$(this).removeClass("active");
			$(this).val("");
		}
	});

	// Copiar código do Pix Emv
	$(document).on('click', "button#pix-emv-code, button#copyable-code", function () {
		const button = $(this);
		const originalText = button.find('span').html();
		const value = button.val();
		if (copyTextToClipboard(value)) {
			button.find('span').html('Copiado!');
			setTimeout(function () {
				button.find('span').html(originalText);
			}, 2000);
		}
	});

	$('body').on('submit', 'form', function (event) {
		var form = $(this);

		if (form.attr('id') === 'form-payment-gateway-card' || form.attr('id') === 'form-payment-gateway') {
			return true;
		}

		// Não valida para form da RDStation
		// if ($(this).parent().parent().hasClass('.rdstation-popup')) {
		// 	return true;
		// }

		// NEWMKX
		var validacao = validateForm(form);
		if (!validacao) {
			return false;
		}

		if (form.attr("target") == undefined || form.attr("target") != "_blank") {
			pageLoadingOn();
		}

		if (form.find("input[name=g-recaptcha-response]").length == 1 && form.attr('id') != 'form-cielo' && form.attr('id') != 'form-paymet' && form.attr('id') != 'form-mercadopago') {

			//Google reCAPTCHA v3
			event.preventDefault();
			var gInput = form.find("input[name=g-recaptcha-response]");
			var site_key = gInput.attr("data-sitekey");
			var action_name = gInput.attr("data-action");
			grecaptcha.ready(function () {
				grecaptcha.execute(site_key, { action: action_name })
					.then(function (token) {
						gInput.val(token);
						var validacao = ajaxForms(form);
						if (!validacao) {
							return false;
						}
					});
			});
			//FIM Google reCAPTCHA v3
		} else {
			var validacao = ajaxForms(form);
			if (!validacao) {
				return false;
			}
		}
	});

	$(".card-unit").on("click", function (){
		$(".card-unit").removeClass('active')
		$(".location-unit").removeClass('active')

		const slugLocation = $(this).attr("data-tab")
		const loc = "loc-"+slugLocation

		$(`#${loc}`).addClass('active')
		$(this).addClass('active')
	})


	/**
	* Tabs
	*/
	$('.mkx-tabs a').click(function () {
		if (!$(this).hasClass('active')) {
			var tab = $(this).parent().parent().attr('data-tab'); // Referência a qual aba
			var panel = $(this).attr('data-panel'); // Referência a qual painel

			$(".mkx-tabs[data-tab='" + tab + "'] a").removeClass('active'); // Desmarca todos
			$(this).addClass('active'); // Marca o clicado

			$(".mkx-panes[data-tab='" + tab + "'] > div").hide().css({ 'paddingLeft': 25, 'opacity': 0 }); // Efeitos a gosto
			$(".mkx-panes[data-tab='" + tab + "'] #" + panel).css({ 'display': 'block' }).animate({ 'paddingLeft': 0, 'opacity': 1 }); // Efeitos a gosto
		}
	})

	$('#desktop-menu-header .datatab').on('mouseover', function() {
		const dataTab = $(this).attr('data-tab');

		const dropdown = '.dropdown-'+dataTab

		$('.dropdown-links-header').removeClass('active')
		$(dropdown).addClass('active')
	})

	$('#desktop-menu-header .links, .dropdown-links-header').on('mouseleave ', function() {
		$('.dropdown-links-header').removeClass('active')
	})

	/* [inicio] - Parallax */
	// if ($(".banner-parallax").length > 0) {
	// 	$(".img-parallax").css("background-image", "url(" + $(".banner-parallax").data("src") + ")");
	// 	var parallaxInicio = ($(".banner-parallax").offset().top - $(window).innerHeight());
	// 	var parallaxFinal = ($(".banner-parallax").offset().top + $(".banner-parallax").height());
	// 	var parallaxDiff = $(".banner-parallax").data("area") / (parallaxFinal - parallaxInicio);
	// 	if ($(window).scrollTop() > parallaxInicio && $(window).scrollTop() < parallaxFinal) {
	// 		var coords = 'center ' + (($(window).scrollTop() - parallaxInicio) * parallaxDiff + ((100 - $(".banner-parallax").data("area")) / 2)) + '%';
	// 		$(".img-parallax").css("background-position", coords);
	// 	}
	// }
	// $(window).scroll(function (event) {
	// 	if ($(".banner-parallax").length > 0) {
	// 		var parallaxInicio = ($(".banner-parallax").offset().top - $(window).innerHeight());
	// 		var parallaxFinal = ($(".banner-parallax").offset().top + $(".banner-parallax").height());
	// 		var parallaxDiff = $(".banner-parallax").data("area") / (parallaxFinal - parallaxInicio);
	// 		if ($(window).scrollTop() > parallaxInicio && $(window).scrollTop() < parallaxFinal) {
	// 			var coords = 'center ' + (($(window).scrollTop() - parallaxInicio) * parallaxDiff + ((100 - $(".banner-parallax").data("area")) / 2)) + '%';
	// 			$(".img-parallax").css("background-position", coords);
	// 		}
	// 	}
	// });

	showRatingWhenInView(".product-thumb-info .ratings .rating-box input");
	showRatingWhenInView("#comments .rating-box input");

	/*
	* Countdown dos thumbs
	*/
	var os = $('.countdown');
 	if (os.length > 0) { 
		await import('jquery-countdown');
		for (var i = 0; i< os.length; i++){
			var o = $($('.countdown').get(i));
			var date = o.attr("data-data");
			var date2 = date.split(" ");
			var date3 = date2[0].split("-");
			var date4 = date2[1].split(":");
			var ano = parseInt(date3[0]);
			var mes = parseInt(date3[1])-1;
			var dia = parseInt(date3[2]);
			var hora = parseInt(date4[0]);
			var minuto = parseInt(date4[1]);
			const countTo = new Date(ano, mes, dia, hora);
			$(o).countdown(date)
				.on('update.countdown', function(event) {
					// Foramtação em https://hilios.github.io/jQuery.countdown/documentation.html
					// na seccção Formatter (event.strftime)
					let format = '%H:%M:%S';
					if(event.offset.totalDays > 0) {
						format = '%-n dia%!n ' + format;
					}
					$(this).html(event.strftime(format));
				})
				.on('finish.countdown', function(event) {
					$(this).html('Oferta Expirou!')
						.parent().addClass('disabled');
				});
		}
	 }

	 $('.header-top-carousel').slick({
		dots: false,
		arrows: false,
		infinite: true,
		vertical: true,
		verticalSwiping: true,
		autoplay: true,
		draggable: false,
		slidesToShow: 1,
		touchThreshold: 1000,
		slidesToScroll: 1,
		lazyLoad: 'ondemand',
	});
	
	$('.bannerPrincipal').slick({
		dots: true,
		arrows: false,
		autoplay: true,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		touchThreshold: 1000,
		responsive: [
			{
				breakpoint: 2000,
				settings: {
					centerMode: false,
					centerPadding: '50px'
				}
			},
			{
				breakpoint: 3840,
				settings: {
					centerMode: true,
					centerPadding: '20%'
				}
			},
		],
	});

	$('.bannerPrincipalMobile').slick({
		dots: true,
		arrows: false,
		autoplay: true,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		touchThreshold: 1000,
		mobileFirst: true,
	});

	$('.blog-carousel').slick({
		dots: true,
		arrows: false,
		infinite: false,
		autoplay: false,
		speed: 300,
		slidesToShow: 5,
		touchThreshold: 1000,
		slidesToScroll: 5,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			
		]
	});
	$('.comments-carousel').slick({
		dots: true,
		arrows: true,
		infinite: false,
		autoplay: false,
		speed: 300,
		slidesToShow: 5,
		touchThreshold: 1000,
		slidesToScroll: 5,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					arrows: false,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			
		]
	});
	$('.minibanner-carousel').slick({
		dots: false,
		arrows: false,
		infinite: false,
		autoplay: false,
		speed: 300,
		slidesToShow: 5,
		touchThreshold: 1000,
		slidesToScroll: 5,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					dots: true,
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					arrows: false,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
			
		]
	});

	$(document).on("click", "#overlay-geral", closeAllMenus);

	$(document).on("click", ".openNav", () => openNav());
	$(document).on("click", ".closeNav", () => closeNav());

	// Abrir Dropdown Menu
	$(document).on("click", ".desktop-header ul li.user-dropdown .signin", function (e) {
		e.preventDefault();
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").css("display", "block");
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").addClass("active");
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").css("visibility", "visible");
		$("#overlay-geral").addClass("active");
	});
	// Fechar Dropdown Menu
	$(document).on("click", "#header .dropdownuserlogin > div span.iconfechar svg", function () {
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").css("display", "none");
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").css("visibility", "collapse");
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").removeClass("active");
		$("#overlay-geral").removeClass("active");
	});

	// Fechar Menu clicando em qualquer lugar da página
	$(document).on("click", "#overlay-geral.active", function () {
		if ($(".desktop-header ul li.user-dropdown .dropdown-menu").hasClass("active")) {
			$("#header .dropdownuserlogin > div span.iconfechar svg").trigger("click");
		}
	});

	$('.desktop-header ul li.atendimento .iconatendimento, .desktop-header ul li.atendimento .setabaixo').on("click", () => {
		$('#header .dropdown-atendimento').addClass('active')
		$("#overlay-geral").addClass("active");
	})

	$('.dropdown-atendimento .iconfechar svg, #overlay-geral').on("click", function() {
		$('#header .dropdown-atendimento').removeClass('active')
		$("#overlay-geral").removeClass("active");
	})

	$('#desktop-menu-header > ul > li.cep span, #desktop-menu-header > ul > li.cep .cep-text, #cep-mobile > .wrapper').on("click", () => {
		$('#header .dropdown-cep').addClass('active')
		$("#overlay-geral").addClass("active");
	})

	$('.dropdown-cep .iconfechar, #overlay-geral').on("click", function() {
		$('#header .dropdown-cep').removeClass('active')
		$("#overlay-geral").removeClass("active");
	})

	$('.dropdown-cep input').on('input', function(){
    var cep = $(this).val().replace(/\D/g, '');
    if(cep.length == 8){
      cep = cep.replace(/^(\d{5})(\d{3})$/, "$1-$2");
      $(this).val(cep);
    }
	})

	$("#header .dropdownuserlogin .login-div form div.input-password span").on("click", function() {
		const inputType = $("#personPassword1").prop("type")
		$(this).removeClass('active')
		if (inputType === 'password') {
			$("#personPassword1").prop("type", "text");
			$('span.iconpasswordon').addClass('active')
			return;
		}

		$("#personPassword1").prop("type", "password");
		$('span.iconpasswordoff').addClass('active')

	})
	

	// Exibir tooltip para Fast Server
	// if ($('.qtiptxt').length > 0) {
	// 	$('.qtiptxt').qtip({
	// 		hide: {
	// 			fixed: true,
	// 			delay: 1000
	// 		},
	// 		content: {
	// 			text: $('.tooltiptext')
	// 		},
	// 		style: {
	// 			classes: 'qtip-bootstrap'
	// 		},
	// 		position: { my: 'bottom-left', at: 'top-right' }
	// 	});
	// }
});