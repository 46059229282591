import { dispatchAddToCartEvent, dispatchRemoveFromCart } from './events.js';
import { reloadicons } from './svg.js';

jQuery(function () {
	
	/* atualiza o numero no icone do carrinho */
	updateTextQtdIcon();
	updateSkillBar();

	// Aplicar CUPOM no carrinho
	$(document).on('click','#miniCart .btn-cupom', function(){
		const coupon = $('#miniCart .mc-footer .cupom input[name=coupon]').val();
	
		addCoupon(coupon);
	});

	$(".product-thumb .product-thumb-purchase .mc-qtd-btns .qtd_add").on("click", function(e){
		e.preventDefault();
		e.stopPropagation();
		var mc_lid = $(this).attr("data-thumbproductid");
		var element_qtd = $(".product-thumb .product-thumb-purchase .mc-qtd-btns input[data-thumbproductid="+mc_lid+"].qtd_number");
		var supply = $(element_qtd).attr("data-supply");
		var amount = element_qtd.val();
		if(parseInt(amount) >= parseInt(supply)){
			if (amount == 1) {
				$(".alertsupply" + mc_lid + " span").html(supply + " item");
			} else {
				$(".alertsupply" + mc_lid + " span").html(supply + " itens");
			}
			$(".alertsupply" + mc_lid).removeClass("displaynone");
			element_qtd.val(supply);
		}else{
			if (!$(".alertsupply" + mc_lid).hasClass("displaynone")) {
				$(".alertsupply" + mc_lid).addClass("displaynone");
			}
			element_qtd.val(++amount);
		}
	});

	$(document).on("click", ".product-thumb .product-thumb-purchase .mc-qtd-btns .qtd_rem", function(){
		var mc_lid = $(this).attr("data-thumbproductid");
		var element_qtd = $(".product-thumb .product-thumb-purchase .mc-qtd-btns input[data-thumbproductid="+mc_lid+"].qtd_number");
		var amount = element_qtd.val()
		var supply = $(element_qtd).attr("data-supply");
		if(parseInt(amount) > parseInt(supply)){
			if (amount == 1) {
				$(".alertsupply" + mc_lid + " span").html(supply + " item");
			} else {
				$(".alertsupply" + mc_lid + " span").html(supply + " itens");
			}
			$(".alertsupply" + mc_lid).removeClass("displaynone");
			element_qtd.val(supply);
		}else{
			if (!$(".alertsupply" + mc_lid).hasClass("displaynone")) {
				$(".alertsupply" + mc_lid).addClass("displaynone");
			}
			if(amount > 1){
				element_qtd.val(--amount);
			}
		}
	});
	$(document).on("change", ".product-thumb .product-thumb-purchase .mc-qtd-btns input", function (e) {
		e.preventDefault();
		e.stopPropagation();
		var mc_lid = $(this).attr("data-thumbproductid");
		var element_qtd = $(".product-thumb .product-thumb-purchase .mc-qtd-btns input[data-thumbproductid="+mc_lid+"].qtd_number");
		var supply = $(element_qtd).attr("data-supply");
		var amount = element_qtd.val();
		if(parseInt(amount) >= parseInt(supply)){
			if (amount == 1) {
				$(".alertsupply" + mc_lid + " span").html(supply + " item");
			} else {
				$(".alertsupply" + mc_lid + " span").html(supply + " itens");
			}
			$(".alertsupply" + mc_lid).removeClass("displaynone");
			element_qtd.val(supply);
		}else{
			if (!$(".alertsupply" + mc_lid).hasClass("displaynone")) {
				$(".alertsupply" + mc_lid).addClass("displaynone");
			}
		}
	});

	

	/* add-cart no thumb */
	$(document).on("click", ".product-thumb-purchase button.add-cart", function () {
		if (!$(this).hasClass('nosupply')) {
			var tiposku = $(this).parent().attr("data-sku");
			var idsku = $(this).attr("data-thumbskuid");
			var idprod = $(this).attr("data-thumbproductid");
			var amount = $('.product-thumb-info .product-quantity input[name=amount][data-thumbproductid=' + idprod + ']').val();
			var addthumb = false;

			if ($(".thumbid" + idprod + " .product-thumb-sku ul li").hasClass("disabled")) {
				return false;
			} else {
				$(".thumbid" + idprod + " .product-thumb-sku ul li").addClass("disabled");
			}

			if (tiposku != undefined) {
				var parentsku = "";
				if (tiposku == "sku1") {
					parentsku = "2";
				} else {
					parentsku = "1";
				}
				$("[data-sku=" + tiposku + "] li").removeClass("actived");
				$(this).attr("disabled", false);
				$(this).addClass("actived");
				var skurelations = $(this).attr("data-skurelations").split(',');
				$("[data-sku=sku" + parentsku + "] li").each(function (i) {
					if (skurelations.includes($(this).attr("data-thumbskuid")) == false) {
						$(this).removeClass("actived");
						$(this).attr("disabled", true);
					} else {
						$(this).attr("disabled", false);
					}
				});
				var sku1 = $("[data-sku=sku1] li.actived");
				var sku2 = $("[data-sku=sku2] li.actived");
				if (sku1 != undefined && sku2 != undefined) {
					var sku1id = sku1.attr("data-thumbskuid");
					var sku2id = sku2.attr("data-thumbskuid");
					if (tiposku == "sku1") {
						if (skurelations.includes(sku2id)) {
							addCart(idprod, sku1id, sku2id, amount);
						} else {
							$("[data-sku=sku2] li").removeClass("actived");
						}
					} else {
						if (skurelations.includes(sku1id)) {
							addCart(idprod, sku1id, sku2id, amount);
						} else {
							$("[data-sku=sku1] li").removeClass("actived");
						}
					}
				}
			} else {
				addCart(idprod, idsku, "", amount);
			}
		}
	});
	/* remove do carrinho */
	$(document).on("click", "#miniCart .btn-cart-remove", function () {
		$(this).html('<i class="iconload"></i>');
		var mlistid = $(this).attr("data-purchaselist");
		reloadicons();
		delCart(mlistid);
	});
	/* aumenta a quantidade no carrinho */
	$(document).on("click", "#miniCart .qtd_add", function () {
		var mc_lid = $(this).attr("data-purchaselist");
		var element_qtd = $("#miniCart ul li[data-purchaselist=" + mc_lid + "] .qtd_number");
		var mc_amount = element_qtd.val();
		qtdCart(mc_lid, mc_amount, element_qtd, 'add');
	});
	/* diminui a quantidade no carrinho */
	$(document).on("click", "#miniCart .qtd_rem", function () {
		var mc_lid = $(this).attr("data-purchaselist");
		var element_qtd = $("#miniCart ul li[data-purchaselist=" + mc_lid + "] .qtd_number");
		var mc_amount = element_qtd.val();
		qtdCart(mc_lid, mc_amount, element_qtd, 'rem');
	});

	/* aumenta a quantidade no carrinho */
	$(document).on("change", "#miniCart .mc-qtd input", function (e) {
		//$("#miniCart.aberto .mc-buttons").removeClass("d-none");
		var mc_lid = $(this).attr("data-purchaselist");
		var element_qtd = $("#miniCart ul li[data-purchaselist=" + mc_lid + "] .qtd_number");
		var mc_amount = element_qtd.val();
		qtdCart(mc_lid, mc_amount, element_qtd, 'change');
	});
	$(document).on("focus", "#miniCart ul .qtd_number", function () {
		//$("#miniCart.aberto .mc-buttons").addClass("d-none");
		//$('#miniCart.aberto').animate({scrollTop: $(this).offset().top},'slow');
		//$("#miniCart.aberto").scrollTop(altura);
	});
	$(document).on("blur", "#miniCart ul .qtd_number", function () {
		//$("#miniCart.aberto .mc-buttons").removeClass("d-none");
	});

});

export function addCoupon(coupon) {
	$.ajax({
		type: "POST",
		url: "/carrinho-de-compra/",
		data: "coupon="+coupon+"&addCoupon=true",
		dataType:"json",
		success: function(data){
			console.log(data)
			// refreshCart();
			if (typeof data.data != 'undefined'){                     
				$("#miniCart .coupon-alert p").html(data.data);
				if(!data.error) {
					$("#miniCart .coupon-alert p").addClass('success');
				}
			}
		}
	});
}
function refreshCart() {
	$.ajax({
		type: "POST",
		url: '/carrinho-de-compra/',
		data: 'headerpurchaselistAjax=true',
		dataType: "json",
		success: function (subjson) {
			if (subjson.error != undefined) {
				alert(subjson.error);
			} else {
				$("#miniCart").html(subjson.data);
				$(document).ready(async function () {
					const { reloadicons } = await import('./svg.js');
					reloadicons();
				});
				updateTextQtdIcon(); // Atualiza o texto do icone do carrinho
				updateTextQtdIconMobile(); // Atualiza o texto do icone do carrinho mobile
				updateSkillBar();
			}
		}
	});
}


/* atualiza o numero no icone do carrinho */
export function updateTextQtdIcon() {
	var totalItens = $("#miniCart input[name=totalItens]").val();
	totalItens = totalItens == "" ? 0 : totalItens;
	$(".desktop-header .items-cart").html(totalItens);
}

export function updateSkillBar(){
	const VALUE_TO_FREE_SHIPPING = 700.00
	const totalValue = $("#miniCart .mc-total .mc-price-subtotal .mc-price").html();
	var hasFreeShipping = false
	var text = $("#miniCart .mc-footer .text-skill p");

	const totalValueWithoutDots = totalValue.replace('.', "");
	const totalValueWithoutComma = totalValueWithoutDots.replace(',', ".");
	const totalValueFloat = parseFloat(totalValueWithoutComma)
	
	const rest = VALUE_TO_FREE_SHIPPING - totalValueFloat;

	const restForFreeShipping = rest.toLocaleString('pt-BR', {
		style: 'currency',
		currency: 'BRL'
	})

	var percent = Math.floor((totalValueFloat * 100) / VALUE_TO_FREE_SHIPPING);

	if(percent >= 100){
		percent = 100;
		hasFreeShipping = true;
	}

	const percentString = percent+"%"
	
	$("#miniCart .container-skill .skill-bar").css("width", percentString)

	if(hasFreeShipping){
		text.html("<strong>Frete Grátis</strong>")
	}else{
		text.html(`Falta(m) <strong>R$ ${restForFreeShipping}</strong> para ter <strong>Frete grátis</strong>`)
	}
	
}

/* atualiza o numero no icone do carrinho mobile */
export function updateTextQtdIconMobile() {
	var totalItens = $("#miniCart input[name=totalItens]").val();
	totalItens = totalItens == "" ? 0 : totalItens;
	$("#mobile-headerLine .items-cart").html(totalItens);
	$("#miniCart .qtd-cart").html(totalItens);
}

export function addCart(pid, sku1, sku2, amount = 1) {
	var aux = "&amount=" + amount;
	if (sku1 != "") {
		aux = aux + "&sku_1=" + sku1;
	}
	if (sku2 > 0) {
		aux = aux + "&sku_2=" + sku2;
	}

	$.ajax({
		type: "POST",
		url: "/carrinho-de-compra/",
		data: "product_id=" + pid + "&addAjax=true&nItens=true" + aux,
		dataType: "json",
		success: function (data) {
			$.ajax({
				type: "POST",
				url: '/carrinho-de-compra/',
				data: 'headerpurchaselistAjax=true',
				dataType: "json",
				success: function (subjson) {
					dispatchAddToCartEvent({ pid, sku1, sku2, amount: parseInt(amount)});
					if (subjson.error != undefined) {
						alert(subjson.error);
					} else {
						$("#miniCart").html(subjson.data);
						let lazyloadImages = document.querySelectorAll(".lazy");
						lazyloadImages.forEach(function (img) {
							img.src = img.dataset.src;
							img.classList.remove('lazy');
						});
						$(document).ready(async function () {
							const { openNav } = await import('./utilities.js');
							openNav();
							const { reloadicons } = await import('./svg.js');
							reloadicons();
						});
						//openNav();
						$(".thumbid" + pid + " .product-thumb-sku ul li").removeClass("actived");
						$(".thumbid" + pid + " .product-thumb-sku ul li").attr("disabled", false);
						updateTextQtdIcon(); // Atualiza o texto do icone do carrinho
						updateSkillBar();
						updateTextQtdIconMobile(); // Atualiza o texto do icone do carrinho mobile
					}
				}
			}).done(function () {
				$(".thumbid" + pid + " .product-thumb-sku ul li").removeClass("disabled");
			});
		}
	});
}

export function delCart(lid) {
	$.ajax({
		type: "GET",
		url: "/carrinho-de-compra/",
		data: "purchase_list_id=" + lid + "&remove=item",
		dataType: "json",
		success: function (data) {
			dispatchRemoveFromCart({ list_id: lid });
			$("#miniCart ul li[data-purchaselist=" + lid + "]").fadeOut("slow", function () {
				$("#miniCart ul li[data-purchaselist=" + lid + "]").remove();
			});

			if (data.totalItens > 0) {
				$("#miniCart .mc-price").html(data.totalFormat);
				$("#miniCart .mc-price-subtotal .mc-price").html(data.totalProductFormat);
			} else {
				$("#miniCart .mc-price").html("0,00");
			}

			$("#miniCart input[name=totalItens]").val(data.totalItens);
			updateTextQtdIcon(); // Atualiza o texto do icone do carrinho
			updateSkillBar();
			updateTextQtdIconMobile(); // Atualiza o texto do icone do carrinho mobile
			updateCarrierText("#miniCart .mc-frete"); // Atualizar Informação sobre Frete
		}
	});
}

export function qtdCart(lid, amount, element, act) {
	if (act == 'rem' && amount > 1) {
		amount--;
	} else if (act == 'add') {
		amount++;
	} else if (act == 'change') {
		if (amount <= 0 || isNaN(amount) || amount == "" || amount == undefined) {
			amount = 1;
		}
	}

	$.ajax({
		type: "GET",
		url: "/carrinho-de-compra/",
		data: "purchase_list_id=" + lid + "&amount=" + amount,
		dataType: "json",
		success: function (data) {
			$.each(data.itens, function (i, item) {
				if (item.list_id == lid) {
					if (item.amount < amount) {
						if (item.amount == 1) {
							$(".alertsupply" + lid + " span").html(item.amount + " item");
						} else {
							$(".alertsupply" + lid + " span").html(item.amount + " itens");
						}
						$(".alertsupply" + lid).removeClass("displaynone");
					} else {
						if (!$(".alertsupply" + lid).hasClass("displaynone")) {
							$(".alertsupply" + lid).addClass("displaynone");
						}
						element.val(amount);
					}
				}
			});
			$("#miniCart .mc-price").html(data.totalFormat);
			$("#miniCart .mc-price-total .mc-price").html(data.totalProductVistaFormat);
			$("#miniCart input[name=totalItens]").val(data.totalItens);
			updateTextQtdIcon(); // Atualiza o texto do icone do carrinho
			updateSkillBar();
			updateTextQtdIconMobile(); // Atualiza o texto do icone do carrinho mobile
			updateCarrierText("#miniCart .mc-frete"); // Atualizar Informação sobre Frete
		}
	});
}

// Atualizar Informação sobre Frete
export function updateCarrierText(element) {
	$.ajax({
		type: "POST",
		url: "/carrinho-de-compra/",
		data: "purchaseListCarrierAjax=true",
		dataType: "json",
		success: function (json) {
			$(element).html(json.data);
		}
	});
}